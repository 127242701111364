<template>
  <div class="tablet">
    <div class="container">
      <div class="line-wrapper">
        <div class="tablet__title">
          <div class="tablet__title-main">
            <h2>{{ $t(this.sliderTitle) }}</h2>
          </div>
          <div class="tablet__title-text">{{ $t(this.sliderText) }}</div>
        </div>
        <div class="tablet__body-container" v-if="!this.isPc">
          <div class="tablet__body">
            <div class="tablet__image">
              <div class="tablet__image-inner">
                
                  <img :src="sliderMobileImg" alt="" loading="lazy" />
                
              </div>
              <div class="tablet__image-inner">
                  <img :src="sliderPcImg" alt="" loading="lazy" />
              </div>
              <div class="tablet__image-inner">
                  <img :src="sliderTabletImg" alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="tablet__body">
          <Swiper :slides-per-view="1.2" :space-between="50">
            <SwiperSlide>
              <div class="tablet__swiper-image">
                  <img :src="sliderMobileImg" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="tablet__swiper-image">
                  <img :src="sliderPcImg" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="tablet__swiper-image">
                  <img :src="sliderTabletImg" alt="" loading="lazy" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import 'swiper/swiper.min.css';

export default {
  name: "Slider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data:() =>  ({
    isMobile: false,
    isPc: window.matchMedia('(max-width: 575px)').matches,
  }),
  props: {
    sliderText: {
      type: String,
    },
    sliderTitle:{
      type: String,
    },
    sliderMobileImg:{
      type: String,
      required: true,
    },
    sliderPcImg:{
      type: String,
    },
    sliderTabletImg:{
      type: String,
    }
  },
  methods: {
    setIsPc(){
      this.isPc = window.matchMedia('(max-width: 575px)').matches;
    }
  },
  mounted() {
    window.addEventListener("resize", this.setIsPc);
  },
  unmounted() {
    document.removeEventListener('resize', this.setIsPc);
  },

}
</script>

<style lang="scss" scoped>
@import "../../base/styles/global.css";

.tablet{
  .container{
    padding: 0 12px;
  }
  @media(max-width: 991px){
    padding: 48px 0px;
  }

  @media(max-width: 575px){
    padding: 48px 0px;
    .container{
      padding: 0 12px 48px 12px;
    }
  }

  &__body-container{
    width: 100%;
    background-color: #E5EEF7;
    @media(max-width: 575px){
      min-width: 320px;
    }
  }
  &__title{
    @media(min-width: 992px){
      display: flex;
      flex-direction: column;
    }

    &-text{
      margin-top: 30px;
      max-width: 565px;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;

      @media(max-width: 1199px) {
        flex: 0 1 485px;
        padding-right: 20px;
        margin-left: 88px;
      }

      @media(max-width: 992px){
        margin-left: 0px;
        margin-top: 20px;
      }

      @media(max-width: 575px){
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }

  &__body{
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 48px;
    padding: 30px 60px;

    @media(max-width: 991px) {
      margin-top: 48px;
    }

    @media(max-width: 767px){
      padding: 0px 0px;
    }

    @media(max-width: 575px){
      margin-top: 45px;
      margin-left: -12px;
      margin-right: -12px;
    }

    img{
      width: 100%;
      min-height: 100%;
      object-fit: contain;
    }
  }

  &__image{
    display: flex;
    &-inner:nth-child(1){
      max-width: 130px;
      margin-bottom: 40px;
      margin-right: -40px;
      @media(max-width: 1200px) {
        max-width: 90px;
      }
      @media(max-width: 992px) {
        max-width: 60px;
        margin-right: -10px;
      }
      @media(max-width: 575px) {
      max-width: unset;
      margin: 0;
      }
    }
    &-inner:nth-child(2){
      @media(max-width: 992px) {
        max-width: 305px;
      }

    }
    &-inner:nth-child(3){
      max-width: 325px;
      margin-bottom: 40px;
      margin-left: -40px;
      @media(max-width: 1200px) {
        max-width: 290px;
      }
      @media(max-width: 992px) {
        max-width: 145px;
        margin-left: -10px;
      }
    }
    &-inner{
      display: flex;
    }
  }

  &__image:nth-child(even){
    margin-top: 100px;
    margin-left: 60px;

    @media(max-width: 767px){
      margin-left: 20px;
    }
  }

  .swiper{
    width: 100%;
    img{
      height: 300px;
    }
  }
}
</style>