<template>
  <section class="look">
    <div class="container">
      <div class="look__title">
        <h1>{{ $t('cases_page.ukrpol.title') }}</h1>
      </div>
      <div class="look__text">{{ $t('cases_page.ukrpol.desc') }}</div>
    </div>
  </section>

  <section class="presentation">
    <div class="container">
      <div>
        <picture>
          <source srcset="../assets/cases/ukrpol/ukrpol_presentation2.webp" type="image/webp">
          <img class="presentation__image" src="../assets/cases/ukrpol/ukrpol_presentation2.png" alt="" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="line-wrapper">
        <div class="about__inner">
          <div class="about__tech">
            <div class="about__tech-item">
              <div class="about__tech-desc">
                {{ $t('cases_page.ukrpol.category') }}
              </div>
              <span>{{ $t('cases_page.ukrpol.category_list') }}</span>
            </div>
            <div class="about__tech-item">
              <div class="about__tech-desc">{{ $t('cases_page.ukrpol.services') }}</div>
              <span>{{ $t('cases_page.ukrpol.services_list') }}</span>
            </div>
            <div class="about__tech-item">
              <div class="about__tech-desc">{{ $t('cases_page.ukrpol.about_tech') }}</div>
              <span>{{ $t('cases_page.ukrpol.about_tech_list') }}</span>
            </div>
          </div>
          <div class="about__info">
            <div>
              <h2 class="about__info-title">{{ $t('cases_page.ukrpol.about_info') }}</h2>
            </div>
            <div class="about__info-text">{{ $t('cases_page.ukrpol.about_info_desc') }}</div>
            <div class="about__info-btn" v-for="(project, i) in cases" :key="i">
              <a class="btn__general btn__general-black" :href="project.path" rel="nofollow" target="_blank">
                {{ $t('cases_page.ukrpol.project_link') }}
                <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z"
                    fill="#2E3139" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="goal">
    <div class="container">
      <div class="line-wrapper">
        <div class="goal__inner">
          <div class="goal__title">
            <h2>{{ $t('cases_page.ukrpol.goal_title') }}</h2>
          </div>
        </div>
        <ul>
          <li>{{ $t('cases_page.ukrpol.goal_title_list_1') }}</li>
          <li>{{ $t('cases_page.ukrpol.goal_title_list_2') }}</li>
          <li>{{ $t('cases_page.ukrpol.goal_title_list_3') }}</li>
          <li>{{ $t('cases_page.ukrpol.goal_title_list_4') }}</li>
          <li>{{ $t('cases_page.ukrpol.goal_title_list_5') }}</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="learn-more">
    <div class="container">
      <div class="learn-more__inner">
        <div class="learn-more__title">
          <h2>{{ $t('cases_page.ukrpol.learn_more_title') }}</h2>
        </div>
        <div class="learn-more__text" v-html="$t('cases_page.ukrpol.learn_more_desc')"></div>
      </div>
    </div>
  </section>

  <section class="changes">
    <div class="container">
      <div class="line-wrapper">
        <div class="changes__inner">
          <div class="changes__item">
            <img src="../assets/project_logo.svg" alt="">
            <div class="changes__item-text">{{ $t('cases_page.ukrpol.changes_new_logo') }}</div>
          </div>
          <div class="changes__item">
            <img src="../assets/logo_project.svg" alt="">
            <div class="changes__item-text">{{ $t('cases_page.ukrpol.changes_old_logo') }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features">
    <div class="container">
      <div class="line-wrapper">  
        <div class="features__inner">
          <div class="features__info">
            <div class="features__info-wrapper">
              <div>
                <h2 class="features__info-title">{{ $t('cases_page.ukrpol.features-title') }}</h2>
              </div>
            </div>
          </div>
        </div>
        <picture>
          <source srcset="../assets/cases/ukrpol/features_img.webp" type="image/webp">
          <img class="features__image" src="../assets/cases/ukrpol/features_img.png" alt="" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>

  <section class="mobile">
    <div class="container">
      <div class="line-wrapper">
        <div class="mobile__body-container">
          <div class="mobile__title">
            <div class="mobile__title-main">
              <h2>{{ $t('cases_page.ukrpol.mobile_title') }}</h2>
            </div>
            <div class="mobile__title-text">{{ $t('cases_page.ukrpol.mobile_desc_1') }}</div>
          </div>

          <div class="mobile__body">
            <div class="mobile__video">
              <iframe width="280" height="586" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FUkmhmQnQeE5kRcRIGSMgit%2FUkrpol-Prototype%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-137%26viewport%3D626%252C700%252C0.08%26t%3DjWmdSzrhMnl59vEG-1%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A137%26mode%3Ddesign" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <CaseSlider 
    sliderTitle="cases_page.ukrpol.tablet_title" 
    sliderText="cases_page.ukrpol.table_desc"
    :sliderMobileImg="require('../assets/cases/ukrpol/ukrpol_mobile.png')" 
    :sliderPcImg="require('../assets/cases/ukrpol/ukrpol_laptop.png')"
    :sliderTabletImg="require('../assets/cases/ukrpol/ukrpol_tablet.png')" 
  />

  <section class="conclusion">
    <div class="container">
      <div>
        <h2 class="conclusion__title">{{ $t('cases_page.ukrpol.conclusion_title') }}</h2>
      </div>
      <div class="conclusion__text">{{ $t('cases_page.ukrpol.conclusion_desc[0]') }}</div>
      <div class="conclusion__text">{{ $t('cases_page.ukrpol.conclusion_desc[1]') }}</div>
    </div>

    <WhatWasDone 
      :leftImg="require('../assets/cases/ukrpol/Ukrpol_conclusion_img-left.png')"
      :rightImg="require('../assets/cases/ukrpol/Ukrpol_conclusion_img-right.png')"
    />
  </section>

  <RecentProjects2 />
  <section class="contact">
    <Contact />
  </section>

</template>

<script>

import Contact from "../components/ContactsNew";
import CaseSlider from "../components/CaseSliders/Slider.vue";
import WhatWasDone from "../components/WhatWasDone.vue";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import RecentProjects2 from "../components/RecentProjects2";

export default {
  name: "caseTypical",
  components: {
    WhatWasDone,
    CaseSlider,
    RecentProjects2,
    Contact,
  },
  setup() {
    const siteData = reactive({
      title: "Modern digital appearance development for Ukrpol: case study",
      description: "Webmil web-production shares a case study on how we designed a fast-loading site with clear products visualization for a leading printing company",
      keywords: "rebranding, software development",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data: () => ({

    cases: [
      { name: 'Launch the site', path: 'https://ukrpol.ua' }
    ],
    recentCases: [
      { id: 1, name: 'View all cases', path: '/cases/' }
    ],
  }),
  methods: {
    handleScroll() {
      let featuresSection = document.querySelector(".features");
      let featuresInfo = featuresSection.querySelector(".features__info-wrapper");

      let rect = featuresSection.getBoundingClientRect();

      if (rect.top < 150) {
        featuresInfo.style.position = "sticky";
        featuresInfo.style.top = "0px";
      } else {
        featuresInfo.style.position = "static";
        featuresInfo.style.top = null;
      }
    },
  },
  mounted() {
    if (window.matchMedia('(min-width: 992px)').matches) {
      document.addEventListener('scroll', this.handleScroll)
    }
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="scss">
@import "../base/styles/media";
@import "../base/styles/fonts";

.project{
  padding-top: 112px !important;
  @media(max-width: 575px) {
    h2{
      font-size: 24px !important;
    }
    padding-top: 48px !important;
  }
}

iframe{
  color: #688E93;
  canvas{
    width: 280px !important;
  }
}

.line-wrapper {
  padding-bottom: 96px;
  border-bottom: 1px solid #DADADA;
  @media(max-width: 575px) {
   padding-bottom: 48px;
  }
}

.contact {
  margin-top: 96px;
  overflow: hidden;
  @media(max-width: 575px) {
    margin-top: 48px;
  }
}

.look {
  margin-top: 154px;

  @media(max-width: 991px) {
    margin-top: 135px;
  }

  @media(max-width: 575px) {
    margin-top: 96px;
  }

  &__title {
    h1 {
      width: 100%;
      max-width: 840px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 465px;
    margin-top: 30px;

    @media(max-width: 991px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
      max-width: 400px;
    }

    @media(max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}

.presentation {
  position: relative;
  margin-top: 64px;

  .container {
    padding: 0 12px;
    @media(max-width: 575px) {
      height: 175px;
      overflow: hidden;
      div{
        height: 100%;
      }
    }

  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
    padding-top: 0;
  }

  &__image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about {
  margin-top: 64px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__tech {
    flex: 0 1 50%;

    @media(min-width: 992px) {
      padding-top: 30px;
    }

    &-item {
      &:not(:first-child) {
        margin-top: 30px;

        @media(max-width: 991px) {
          margin-top: 30px;
        }

        @media(max-width: 575px) {
          margin-top: 0px;
        }
      }

      @media(max-width: 575px) {
        display: flex;
        align-items: center;
      }
    }

    &-desc {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #2E3139;
      position: relative;

      @media(max-width: 991px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 575px) {
        font-size: 18px;
        line-height: 24px;
        position: relative;

        &:after {
          content: ':';
          position: absolute;
          top: 0;
          right: -5px;
          display: inline-block;
          font-size: 17px;
          margin-left: -3px;
        }
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;

        &:after {
          content: ':';
          position: absolute;
          bottom: 0;
          right: -5px;
          display: inline-block;
          font-size: 13px;
        }
      }
    }

    span {
      display: inline-block;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media (min-width: 576px) {
        margin-top: 10px;
      }

      @media (max-width: 575px) {
        margin-left: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__info {
    flex: 0 1 50%;
    max-width: 502px;

    @media(min-width: 992px) {
      margin-left: 20px;
    }

    @media(max-width: 991px) {
      margin-top: 80px;
    }

    @media(max-width: 575px) {
      margin-top: 30px;
    }

    &-title {
      color: #2E3139;
    }

    &-text {
      max-width: 502px;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-btn {
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 30px;
      }

      @media(max-width: 575px) {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.goal {
  margin-top: 96px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  ul {
    @extend .fonts__ul;
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__main {
    flex: 0 1 502px;
    padding-top: 20px;

    @media(max-width: 575px) {
      padding-top: 10px;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.features {
  margin-top: 48px;

  .container {
    img {
      margin-top: 30px;
      width: 100%;
    }
  }

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__info {
    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    ul {
      @extend .fonts__ul;
      margin-top: 12px;
    }
  }

  &__site {
    flex: 0 1 606px;

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 1399px) {
      flex: 0 1 560px;
    }

    @media(max-width: 1199px) {
      flex: 0 1 470px;
    }

    @media(max-width: 991px) {
      margin-top: 60px;
    }

    img {
      width: 100%;
    }
  }
}

/////

.learn-more {
  margin-top: 48px;

  @media(max-width: 991px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      flex-direction: column;
    }
  }

  &__text {
    max-width: 564px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    padding-top: 30px;

    @media(max-width: 1199px) {
      padding-right: 0;
    }

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      padding-top: 0px;
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }

    a {
      font-size: 18px;
      font-weight: bold;
      color: #2E3139;
      transition: 0.4s color;

      @media (any-hover: hover) {
        &:hover {
          color: #688E93;
        }
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__subtext {
    flex: 0 1 502px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    padding-top: 20px;

    @media(max-width: 1199px) {
      padding-right: 0;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.changes {
  margin-top: 30px;

  .container {
    padding: 0 12px;
  }

  &__inner {
    background-color: #253746;
    padding: 100px 90px;
  }

  @media(max-width: 991px) {
    padding: 90px 0px;
    margin-top: 30px;
  }

  @media(max-width: 575px) {
    margin-top: 0;
    padding: 0;

    .container {
      padding: 0 12px 48px 12px;
    }

    &__inner {
      padding: 60px 50px;
    }
  }

  @media(min-width: 768px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  @media(min-width: 992px) {
    &__inner {

      width: 100%;

    }
  }

  &__item {
    flex: 0 1 465px;

    &:not(:first-child) {
      margin-left: 100px;
      padding-right: 50px;

      @media(max-width: 991px) {
        margin-left: 60px;
      }

      @media(max-width: 767px) {
        margin-left: 0px;
        margin-top: 60px;
      }

      @media(max-width: 767px) {
        padding: 0px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #C4C4C4;
        margin-top: 10px;
      }
    }

    img {
      width: 100%;
    }
  }
}

.mobile {
  margin-top: 96px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 992px) {
    margin-top: 48px;
  }

  @media(max-width: 575px) {
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  &__body-container {
    display: flex;
    column-gap: 30px;

    @media(max-width: 575px) {
      flex-direction: column;
    }
  }

  &__inner {
    margin-top: 129px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #253746;

    @media(max-width: 991px) {
      margin-top: 90px;
      padding-top: 52px;
      padding-bottom: 52px;
    }

    @media(max-width: 575px) {
      margin-top: 54px;
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }

  &__title {
    max-width: 565px;

    @media (min-width: 992px) {
      display: flex;
      flex-direction: column;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      padding-top: 30px;

      @media(max-width: 1199px) {
        padding-right: 0;
      }

      @media (max-width: 991px) {
        margin-left: 0px;
      }

      @media(max-width: 575px) {
        padding-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-subtext {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      padding-top: 20px;

      @media(max-width: 1199px) {
        padding-right: 0;
      }

      @media (max-width: 991px) {
        margin-left: 0px;
      }

      @media(max-width: 575px) {
        padding-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__body {
    max-width: 606px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 991px) {

      margin-top: 100px;
    }

    @media(max-width: 575px) {
      width: 100%;
      justify-content: center;


      margin-left: -12px;
      margin-right: -12px;
      margin-top: 45px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__video {
    display: flex;
    position: static;
    width: 100%;
    object-fit: contain;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 27px;

      @media(max-width: 767px) {
        border-radius: 29px;
      }
    }

    &-inner {
      border: 10px solid black;
      border-radius: 38px;
      flex: 0 1 280px;
      display: flex;
      overflow: hidden;

      @media(max-width: 767px) {

        border: 8px solid black;
      }
    }

    @media(max-width: 575px) {

      flex: 0 1 200px;
    }
  }


  &__image:nth-child(even) {
    margin-left: 46px;
    margin-top: 100px;
  }
}

.tablet {
  margin-top: 96px;

  .container {
    padding: 0 12px;
  }

  @media(max-width: 991px) {
    padding: 48px 0;
  }

  @media(max-width: 575px) {
    padding: 0 !important;
    margin-top: 0;

    .container {
      padding: 0 12px 48px 12px;
    }
  }

  &__body-container {
    width: 100%;
    background-color: #E5EEF7;

    @media(max-width: 575px) {
      min-width: 320px;
    }
  }

  &__title {
    @media(min-width: 992px) {
      display: flex;
      flex-direction: column;
    }

    &-text {
      max-width: 565px;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;

      @media(max-width: 1199px) {
        flex: 0 1 485px;
        padding-right: 20px;
        margin-left: 88px;
      }

      @media(max-width: 992px) {
        margin-left: 0px;
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }
    }
  }

  &__body {

    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 48px;
    padding: 30px 60px;

    @media(max-width: 991px) {
      margin-top: 48px;
    }

    @media(max-width: 767px) {
      padding: 0px 0px;
    }

    @media(max-width: 575px) {
      margin-top: 45px;
      margin-left: -12px;
      margin-right: -12px;
    }

    img {
      width: 100%;
      min-height: 100%;
      object-fit: contain;
    }
  }

  &__image {
    display: flex;

    &-inner:nth-child(1) {
      max-width: 130px;
      margin-bottom: 40px;
      margin-right: -40px;

      @media(max-width: 1200px) {
        max-width: 90px;
      }

      @media(max-width: 992px) {
        max-width: 60px;
        margin-right: -10px;
      }

      @media(max-width: 575px) {
        max-width: unset;
        margin: 0;
      }
    }

    &-inner:nth-child(2) {
      @media(max-width: 992px) {
        max-width: 305px;
      }

    }

    &-inner:nth-child(3) {
      max-width: 325px;
      margin-bottom: 40px;
      margin-left: -40px;

      @media(max-width: 1200px) {
        max-width: 290px;
      }

      @media(max-width: 992px) {
        max-width: 145px;
        margin-left: -10px;
      }
    }

    &-inner {
      display: flex;
    }
  }

  &__image:nth-child(even) {
    margin-top: 100px;
    margin-left: 60px;

    @media(max-width: 767px) {
      margin-left: 20px;
    }
  }

  .swiper {
    width: 100%;

    img {
      height: 300px;
    }
  }
}

.conclusion {
  margin-top: 48px;

  @media (max-width: 991px) {
    margin-top: 48px;
  }

  @media (max-width: 575px) {
    margin-top: 0;
  }

  &__text {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 590px;
    margin-top: 30px;

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.view-more {
  margin-bottom: 60px;

  @media(max-width: 991px) {
    margin-top: 90px;
    margin-bottom: 130px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
    margin-bottom: 96px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 575px) {
      justify-content: center;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }

  &__button {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    background-color: rgba(46, 49, 57, 1);
    border: 1px solid rgba(46, 49, 57, 1);

    svg>path {
      fill: #fff;
    }

    @media (any-hover: hover) {
      &:hover {
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;

        svg>path {
          fill: rgba(46, 49, 57, 1);
        }
      }
    }

    &:active {
      color: rgba(46, 49, 57, 1) !important;
      border: 1px solid rgba(46, 49, 57, 1);
      background: #fff;

      svg>path {
        fill: rgba(46, 49, 57, 1);
      }
    }

    @media(max-width: 991px) {
      display: none;
    }
  }

  &__button-mobile {
    display: none !important;
  }

  @media(max-width: 991px) {
    &__button-mobile {
      display: inline-flex !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      background-color: rgba(46, 49, 57, 1);
      border: 1px solid rgba(46, 49, 57, 1);
      margin-top: 45px;

      svg>path {
        fill: #fff;
      }

      @media (any-hover: hover) {
        &:hover {
          color: rgba(46, 49, 57, 1) !important;
          background: #fff;

          svg>path {
            fill: rgba(46, 49, 57, 1);
          }
        }
      }

      &:active {
        color: rgba(46, 49, 57, 1) !important;
        border: 1px solid rgba(46, 49, 57, 1);
        background: #fff;

        svg>path {
          fill: rgba(46, 49, 57, 1);
        }
      }
    }
  }


  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 45px;

    @media(max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
    }

    @media(max-width: 767px) {
      grid-template-columns: 1fr;
      row-gap: 60px;
    }
  }
}
</style>
